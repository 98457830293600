<template>
<div class="password-recovery">
    <div class="container">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb pl-0 mb-0 pb-2">
                <li class="breadcrumb-item"><a href="/">{{ $t('breadcrumb.main-page') }}</a></li>
                <li class="breadcrumb-item active" aria-current="page">{{ $t('breadcrumb.password-recovery') }}</li>
            </ol>
        </nav>
        <div class="page-title signup-page-title text-lg-left text-center">
            {{ $t('password-recovery.title') }}
        </div>
    </div>
    <!-- <img src="/images/vave-bg.svg" class="password-recovery__bg"> -->
    <img src="/images/vave-bg-2.png" class="password-recovery__bg" alt="">
    <div class="container" style="position: relative">
        <div class="row">
            <div class="col-lg-6 col-md-12 password-recovery__block">
                <div class="password-recovery__form">
                    <div class="password-recovery__choose-method" v-if="!methods">
                        <button class="password-recovery__method-mail" v-on:click="methods = true; methodEmailStep1 = false; methodEmailStep2 = true">
                            <div class="password-recovery__method-icon">
                                <img src="/images/user-icon.svg" width="20" height="20" alt="">
                            </div>
                            <div class="password-recovery__method-desc">
                                <div class="password-recovery__method-title"> 
                                    {{ $t('password-recovery.method-email-title') }}
                                </div>
                                <div class="password-recovery__method-text">
                                    {{ $t('password-recovery.method-email-desc') }}
                                </div>
                            </div>
                            <div class="password-recovery__method-arrow">
                                <img src="/images/arrow-lila.svg" width="20" height="20" alt="">
                            </div>
                        </button>
<!--
                        <button class="password-recovery__method-sms" v-on:click="methods = true; methodSmsStep1 = false; methodSmsStep2 = true">
                            <div class="password-recovery__method-icon">
                                <img src="/images/luggage-icon.svg" width="20" height="20">
                            </div>
                            <div class="password-recovery__method-desc">
                                <div class="password-recovery__method-title"> 
                                    {{ $t('password-recovery.method-sms-title') }}
                                </div>
                                <div class="password-recovery__method-text">
                                    {{ $t('password-recovery.method-sms-desc') }}
                                </div>
                            </div>
                            <div class="password-recovery__method-arrow">
                                <img src="/images/arrow-lila.svg" width="20" height="20">
                            </div>
                        </button>
                        -->
                    </div>
                    <div class="password-recovery__email-step-1" v-if="!methodEmailStep1">
                        <form-group :field="$v.account.email" :label="$t('password-recovery.email-label')" name="email">
                            <el-input
                                id="email"
                                :placeholder="$t('profile.profile.email-placeholder')"
                                v-model="account.email"
                                @blur="onGetEmail"
                                clearable>
                            </el-input>
                        </form-group>
                        <form-group @submit="submit(account)" :field="$v.account.iin" :label="$t('signin.select-user-label')" name="iin">
                            <el-select style="width: 100%" v-model="account.iin" :no-data-text="$t('signin.empty')" :placeholder="$t('signin.select-user-label')" :loading="iinLoading">
                                <el-option
                                    v-for="iin in iinArray"
                                    @change="$v.account.iin.$touch()"
                                    :key="iin.value"
                                    :label="iin.label"
                                    :value="iin.value">
                                </el-option>
                            </el-select>
                        </form-group>
                        <div class="text-center">
                            <button class="password-recovery__next-btn" v-on:click="sendConfirmation">
                                {{ $t('password-recovery.next-btn') }}
                            </button>
                        </div>
                    </div>
                    <div class="password-recovery__email-step-2" v-if="!methodEmailStep2">
                        <div class="password-recovery__step-2-title">
                            {{ $t('password-recovery.method-email-title') }}
                        </div>
                        <div v-html="$t('password-recovery.email-desc-finish')" class="password-recovery__step-2-desc"></div>
                        <div class="password-recovery__step-2-block">
                            <div>
                                {{ $t('password-recovery.did-not-get-email') }}
                            </div>
                            <ul class="password-recovery__step-2-list">
                                <li>{{ $t('password-recovery.email-advice-1') }}</li>
                                <li>{{ $t('password-recovery.email-advice-2') }}</li>
                            </ul>
                            <div v-html="$t('password-recovery.email-advice-3')"></div>
                        </div>
                    </div>

                    <div class="password-recovery__sms-step-1" v-if="!methodSmsStep1">
                        <form-group :field="$v.account.phone" :label="$t('signin.phone-placeholder')" name="phone">
                            <el-input
                                :placeholder="$t('signin.phone-placeholder')"
                                v-model="account.phone"
                                v-mask="'+7 (###) ### ##-##'"
                                @input="enforcePhoneFormat"
                                @blur="onGetEmail"
                                :disabled="iinLoading"
                                autocomplete="off">
                            </el-input>
                        </form-group>
                        <form-group @submit="submit(account)" :field="$v.account.iin" :label="$t('signin.select-user-label')" name="iin">
                            <el-select style="width: 100%" v-model="account.iin" :no-data-text="$t('signin.empty')" :placeholder="$t('signin.select-user-label')" :loading="iinLoading">
                                <el-option
                                    v-for="iin in iinArray"
                                    @change="$v.account.iin.$touch()"
                                    :key="iin.value"
                                    :label="iin.label"
                                    :value="iin.value">
                                </el-option>
                            </el-select>
                        </form-group>
                        <div class="text-center">
                            <button class="password-recovery__next-btn"  v-on:click="methods = true; methodSmsStep1 = true; methodSmsStep2 = false">
                                {{ $t('password-recovery.next-btn') }}
                            </button>
                        </div>
                    </div>
                    <div class="password-recovery__sms-step-2" v-if="!methodSmsStep2">
                        <div class="password-recovery__step-2-title">
                            {{ $t('password-recovery.method-sms-title') }}
                        </div>
                        <div class="password-recovery__step-2-desc">
                            {{ $t('password-recovery.enter-sms-code') }} +7 (XXX) XXX XX-XX
                        </div>
                        <CodeInput :field-width="44" :field-height="56" :loading="sendingSms" :fields="4" class="input" :autoFocus="false"
                                    v-on:change="onChange" v-on:complete="onComplete"/>
                        <div v-if="!canResend" class="password-recovery__resend-sms">{{ $t('password-recovery.resend-code-after') }}{{ resendSec }}
                            {{ $t('password-recovery.resend-code-seconds') }}
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-lg-6 col-md-12 password-recovery__block">
                <div class="password-recovery__logo">
                    <img src="/images/logo.png" width="258" height="213" alt="">
                </div>
            </div>
        </div>
    </div>
</div>

</template>
<script>
import Vue from 'vue'
import VueMask from 'v-mask'
import {mapActions} from "vuex";
import { required, minLength } from 'vuelidate/lib/validators'
import CodeInput from "vue-verification-code-input"

export default {
  name: 'ForgetPassword',
  beforeCreate() {
    Vue.use(VueMask);
  },
  components: {
    CodeInput,
  },
    data () {
        return {
            methods: false,
            methodEmailStep1: true,
            methodEmailStep2: true,
            methodSmsStep1: true,
            methodSmsStep2: true,
            iinLoading: false,
            loading: false,
            phone: '',
            iinArray: [],
            resendSec: 60,
            canResend: false,
            sendingSms: false,
            account: {
                phone: '',
                iin: '',
                password: '',
                email: ''
            }
        }
    },
    methods: {
        onGetEmail() {
            this.iinLoading = true;
            if (!this.$v.account.email.$invalid){
                if (this.account.email !== '') {
                    this.getIinByEmail({
                        email: this.account.email
                    }).then(() => {
                        this.iinLoading = false;
                        this.addOptionsToSelect()
                    }).catch(() => {
                        this.iinLoading = false;
                    })
                }
            }
        },
        addOptionsToSelect() {
            this.iinArray = [];
            let data = JSON.parse(sessionStorage.getItem('children'));

            data.forEach(element => {
                let item = {};
                item.label = element.last_name + " " + element.first_name;
                item.value = element.iin;
                this.iinArray.push(item);
            });
            sessionStorage.removeItem('children');
        },
        sendConfirmation(){
            this.$v.$touch()
            if (this.$v.$invalid) return
            this.$http.post(window.API_ROOT + '/api/password/reset-by-email', {
                iin: this.account.iin
            }).then(() => {
                this.EmailSended = true;
                this.methods = true;
                this.methodEmailStep1 = true;
                this.methodEmailStep2 = false;
            }).catch((e) => {
                Vue.toastr({
                    message: 'Error',
                    description: (e.body.message) ? e.body.message : `Произошла ошибка, попробуйте позднее (Код: ${response.status})`,
                    type: 'error'
                })
            })
        },
        ...mapActions({
            'getIinByEmail': 'getIinByEmail',
        }),
    },
    validations: {
        account: {
            phone: {
                required: false,
                minLength: minLength(11)
            },
            email: {
                required
            },
            iin: {
                required
            }
        }
    }
}
</script>

<style lang="less" scoped>
.password-recovery {
    position: relative;
}
.password-recovery__form {
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    width: fit-content;
    margin: 0 auto;
}
.password-recovery__choose-method {
    padding: 81px 56px;
}
.password-recovery__logo {
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 107px;
    max-width: 473px;
    margin-right: 0;
    margin-left: auto;
    height: 100%;
    min-height: 428px;
}
.password-recovery__block {
    padding-top: 92px;
    padding-bottom: 136px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}
.password-recovery__method-mail {
    background: #EEEAFB;
    box-shadow: 0 4px 14px 1px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    padding: 29px 25px 25px 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 42px;
    border: none;
}
.password-recovery__method-desc {
    padding-left: 27px;
    padding-right: 12px;
    max-width: 400px;
}
.password-recovery__method-mail .password-recovery__method-icon {
    background: #473F95;
    min-width: 54px;
    height: 54px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.password-recovery__method-title {
    color: #000000;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    font-family: 'Inter', 'Roboto', sans-serif;
    text-align: left;
}
.password-recovery__method-text {
    color: #8692A6;
    font-size: 12px;
    line-height: 14px;
    padding-top: 9px;
    text-align: left;
}
.password-recovery__method-sms {
    background: #fff;
    box-shadow: 0 4px 14px 1px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    padding: 29px 25px 25px 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;    
    border: none;
}
.password-recovery__method-sms .password-recovery__method-icon {
    background: #fff;
    border: 2px solid #473F95;
    min-width: 54px;
    height: 54px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.password-recovery__method-sms .password-recovery__method-arrow {
    visibility: hidden;
}
.password-recovery__bg {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1;
    // bottom: 301px;
    bottom: 0;
    max-height: 320px;
}
.password-recovery__email-step-1,
.password-recovery__sms-step-1 {
    padding: 50px;
    max-width: 437px;
}
.password-recovery__next-btn {
    margin-top: 34px;
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    padding: 10px 60px;
    border: none;
    background: #473F95;
    border-radius: 4px;
}
.password-recovery__email-step-2 {
    padding: 45px;
    color: #473F95;
    max-width: 512px;
}
.password-recovery__step-2-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    padding-bottom: 42px;
    text-align: center;
    color: #473F95;
}
.password-recovery__step-2-desc {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    padding-bottom: 42px;
}
.password-recovery__step-2-block {
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
}
.password-recovery__step-2-list {
    margin-top: 16px;
    margin-bottom: 16px;
}
.password-recovery__sms-step-2 {
    padding: 26px 45px;
}
.password-recovery__step-2-desc {
    color: #303030;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 22px;
    text-align: center;
}
.password-recovery__resend-sms {
    color: #D23168;
    text-align: center;
    letter-spacing: 0.1px;
    font-size: 12px;
    line-height: 16px;
    padding-top: 49px;
}
/deep/ .input.react-code-input-container {
    margin: 0 auto;
    width: 254px!important;
}
/deep/ .react-code-input>input {
    box-shadow: 0 4px 4px rgba(51, 51, 51, 0.04), 0 4px 16px rgba(51, 51, 51, 0.08);
    border-radius: 4px;
    background: #FFFFFF;
    border: none;
    color: #473F95;
    font-size: 40px;
    line-height: 24px;
    margin-right: 26px;
}

/deep/ .react-code-input>input:last-child {
    margin-right: 0;
    border-right: none;
}
/deep/ .react-code-input>input:focus {
    border: 1px solid #473F95!important;
}

@media (max-width: 991px) {
    .password-recovery__block {
        padding-top: 24px;
        padding-bottom: 0;
    }
    .password-recovery__logo {
        margin: 0 auto;
    }
    .password-recovery__bg {
        bottom: 520px;
    }
}
@media (max-width: 767px) {
    .password-recovery__logo {
        padding: 107px 10px;
        max-width: none;
        width: 100%;
        margin: 0;
    }
    .password-recovery__form {
        min-height: 441px;
        display: flex;
        align-items: center;
    }
}
</style>



